@use "sass:color";

.mptoggler {
    margin: 0 0 1em 0;
}

.ytvid {
    width: 100%;
    max-width: 600px;
    aspect-ratio: 16/9;
    display: block;

    &.ytshort {
        aspect-ratio: 9/16;
        max-height: 90vh;
        margin: 1em auto;
    }
}

.pagination {
    background-color: #ccc;
    color: #666;
    padding: 0.25em;
    border: 1px solid #000;
    display: flex;

    .page_number {
        margin: 0 1em;
        flex-grow: 1;
        text-align: center;
    }
}

.info {
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

div[class^="language-"] {
    max-height: 20rem;
    overflow: auto;
    margin: 1em 0;
}

p > img,
picture > img {
    box-shadow: 10px 5px 5px #ccc;
    max-width: 100%;
    height: auto;
}

picture > img {
    margin: 0 auto 1em;
    display: block;
}

.footnotes {
    margin-top: 2em;
    font-size: 90%;
    background-color: #f0f0f0;
    padding: 1em 0;
}

.linkurl::after {
    background-image: url(/i/icons8-external-link-32.png);
    content: "";
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 0.5em;
}

.fitvid640 {
    padding-top: calc(480px / 640px) * 100%;
    width: 100%;
    overflow: hidden;
    height: 0;
    position: relative;

    & > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

$body-bg: #fff;
$body-fg: #000;
$pluralsight-bg: color.adjust($body-fg, $lightness: 25%);
$pluralsight-fg: color.adjust($body-bg, $lightness: -25%);

img.scrapbook {
    max-width: 300px;
    border: 2px solid #ccc;
    margin: 0.5em auto;

    @media screen and (min-width: 600px) {
        float: right;
        margin: 0.5em 0 0.5em 1em;
    }
}

.pluralsight-block {
    margin: 1em auto;
    max-width: 960px;
    width: 100%;

    .img {
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .desc {
        background-color: $pluralsight-bg;
        color: $pluralsight-fg;
        margin: 0;
        padding: 0.5em;

        a {
            &,
            &:link,
            &:visited,
            &:active {
                color: $pluralsight-fg;
                text-decoration: none;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @media screen and (min-width: 60rem) {
        display: flex;

        .img {
            max-width: 50%;
        }
    }
}

blockquote.tiktok-embed {
    margin: 1em auto;
    border-left: none;
}

aside {
    padding: 1em;
    background-color: #f1f4de;
    margin-bottom: 1em;
    display: block;

    p {
        margin: 0;
    }
}

.sidebyside {
    display: flex;
    align-items: center;
    gap: 1em;
    flex-direction: column;

    @media screen and (min-width: 600px) {
        flex-direction: row;
    }
}

sup[role="doc-noteref"] {
    background-color: #fbfb08;
    padding: 0 0.25em;

    a,
    a:link,
    a:visited,
    a:active {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
