// <div class="candidate">
//     <img class="candidateshot" src="/images/amber-square@1x.jpg">
//     <div class="candidateinfo">
//         <h3>Amber Yantz for School Board, District 3</h3>
//         <ul>
//             <li><a href="">Learn More and Volunteer</a></li>
//             <li><a href="">Donate!</a></li>
//         </ul>
//     </div>
// </div>

.candidate {
    display: flex;
    flex-direction: row;
    margin: 1em 0;
    column-gap: 2em;
    padding: 10px;
    background-color: #f0f0ff;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        text-align: center;
    }
}

.candidateinfo {
    font-size: 120%;

    h3 {
        font-variant: small-caps;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;
        margin: 1em 0;
    }
}

.candidateshot {
    width: 300px;
    max-width: 100%;
}

.candidatelink {
    display: block;
}
