.hero {
    max-width: 100%;
}

.rss-icon {
    width: 1.5em;
    height: 1.5em;
    box-shadow: none;
    margin-top: 1em;
}

.timeline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
    padding: 0.5em;
    background-color: var(--timeline-date-background-color);
    color: var(--timeline-date-color);
    border-radius: 0.5em;

    h1 {
        font-size: 1.75em;
        margin: 0;
        text-align: center;
        flex: 1;
    }
}

.timeline-rss {
    height: 3rem;
}
